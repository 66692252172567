import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/Layout";

export default function() {

	return (
		<Layout>
		  <div>Lost, man. I'm lost.</div>
		  <div class="container">
			<div class="row">
			  <div className="col-lg-6 offset-lg-3"style={{paddingTop:'20px'}}>
			  	<p>
			  		We put you here by mistake and we're sorry.
			  		Click <Link to="/">Here</Link> for our homepage.
			  	</p>
			  </div>
			</div>
		  </div>
		</Layout>
	);
}
